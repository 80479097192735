import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/browser';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './common/common.module';
import { DefaultViewDirective } from './common/suspense/default-view.directive';
import { ErrorViewDirective } from './common/suspense/error-view.directive';
import { FallbackViewDirective } from './common/suspense/fallback-view.directive';
import { SuspenseComponent } from './common/suspense/suspense.component';
import { FSKDatabaseConfig } from './database/config/fsk-database-config';
import { DatabaseModule } from './database/database.module';
import { SentryErrorHandler } from './error-handler';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './interceptor/timeout.interceptor';
import { MaterialModule } from './material/material.module';
import { ErrorModalComponent } from './site/error/error-modal/error-modal.component';
import { ErrorPageComponent } from './site/error/error-page/error-page.component';
import { ThemeModule } from './site/theme/theme.module';
import { ConnectionErrorModalComponent } from './site/error/connection-error-modal/connection-error-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PlatformWarningComponent } from './site/platform-warning/platform-warning.component';
import { TroubleshootPageComponent } from './site/error/troubleshoot-page/troubleshoot-page.component';
import { LayoutModule } from './site/layout/layout.module';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { BrowserTracing } from '@sentry/tracing';
import { env } from 'process';
import { environment } from '../environments/environment';
import { AuthTokenPrefix, RefreshTokenName, UserbaseAuthStrategy, V1AuthTokenPrefix, V1RefreshTokenName, V1UserbaseAuthStrategy } from '@highfieldabc/angular-highfieldauth';

Sentry.init({
	dsn: 'https://37d8bba73539406596a0e02e8b6b6beb@o341742.ingest.sentry.io/5356011',
	integrations: [
		Sentry.browserTracingIntegration(),
	],
	tracesSampleRate: environment.sentryTraceRate,
	sampleRate: environment.sentryTraceRate,
	release: environment.sentryReleaseVersion,
	environment: environment.sentryEnvironment,
});



@NgModule({
	declarations: [
		AppComponent,
		SuspenseComponent,
		DefaultViewDirective,
		ErrorViewDirective,
		FallbackViewDirective,
		ErrorPageComponent,
		ErrorModalComponent,
		ConnectionErrorModalComponent,
		PlatformWarningComponent,
		TroubleshootPageComponent,
		NotFoundComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		FormsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		QuillModule.forRoot({
			modules: {
				toolbar: [
					['bold', 'italic', 'underline'],        // toggled buttons
					[{ 'list': 'ordered' }, { 'list': 'bullet' }],
					[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
					[{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
					[{ 'direction': 'rtl' }],                         // text direction
					[{ 'align': [] }],
					['clean'],                                         // remove formatting button
					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				]
			},
			customOptions: [{
				import: 'attributors/style/size',
				whitelist: ['8px', '10px', '12px', '14px', '16px', '18px', '20px']
			}]
		}),
		MaterialModule,
		CommonComponentsModule,
		ThemeModule,
		DatabaseModule.forRoot(FSKDatabaseConfig),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: "registerWhenStable:15000" }),
		LayoutModule
	],
	providers: [
		{ provide: ErrorHandler, useClass: SentryErrorHandler },
		{ provide: 'servicesBaseUrl', useValue: environment.servicesBaseUrl },
		{ provide: 'signalRBaseUrl', useValue: environment.signalRBaseUrl },
		{ provide: 'CDNUrl', useValue: environment.CDNUrl },
		[{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
		[{ provide: DEFAULT_TIMEOUT, useValue: 30000 }],
		{ provide: 'dashboardUrl', useValue: environment.dashboardUrl },
		{ provide: 'markingUrl', useValue: environment.markingUrl },
		{ provide: 'QuestionBankSystemId', useValue: environment.questionBankSystemId },
		{ provide: 'CoreExamCDNCategoryId', useValue: environment.coreExamCDNCategoryId },
		{ provide: "CoreExamsUserId", useValue: environment.CoreExamsUserId },
		{ provide: "AuthApiBaseUrl", useValue: environment.AuthApiBaseUrl },
		{ provide: "LoginPageUrl", useValue: environment.LoginPageUrl },
		{ provide: "UserApiBaseUrl", useValue: environment.UserApiBaseUrl },
		{ provide: "LoginGuestPageUrl", useValue: environment.LoginPageUrl },
		{ provide: "RegisterPageUrl", useValue: environment.RegisterPageUrl },
		{ provide: "CDNApiBaseUrl", useValue: environment.CdnApiUrl },
		{ provide: UserbaseAuthStrategy, useClass: V1UserbaseAuthStrategy },
		{ provide: AuthTokenPrefix, useValue: V1AuthTokenPrefix },
		{ provide: RefreshTokenName, useValue: V1RefreshTokenName }
		

	],
	bootstrap: [AppComponent]
})
export class AppModule { }
