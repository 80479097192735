import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { ThemeSelectorModalComponent } from './theme-selector-modal/theme-selector-modal.component';



@NgModule({
  declarations: [ThemeSelectorModalComponent],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class ThemeModule { }
