import { Component, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ThemeService } from './service/theme.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	title = 'Highfield Core';

	public loading = false;

	constructor(private router: Router,
		private themeService: ThemeService,
		private updates: SwUpdate) {

			this.updates.activateUpdate().then(() => document.location.reload());

		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					const navigationEvent: NavigationStart = event as NavigationStart;
					this.loading = true;

					if (navigationEvent.url === '/exam/end' || navigationEvent.url.startsWith('/error')) {
						window.removeEventListener('beforeunload', this.unloadHandler);
					} else if (navigationEvent.url === '/exam') {
						window.addEventListener('beforeunload', this.unloadHandler);
					}
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.loading = false;
					break;
				}
				default: {
					break;
				}
			}
		});
	}

	public ngOnInit(): void {
		this.themeService.init();
	}

	public unloadHandler(e: BeforeUnloadEvent): void {
		e.preventDefault();
		e.returnValue = true;
	}
}
