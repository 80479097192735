import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PlatformService } from '../../service/platform.service';

@Component({
	selector: 'app-platform-warning',
	templateUrl: './platform-warning.component.html',
	styleUrls: ['./platform-warning.component.scss']
})
export class PlatformWarningComponent implements OnInit {
	public returnUrl: string = '';

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private platformService: PlatformService) { }

	public ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params: Params) => {
			this.returnUrl = params['returnUrl'];
		});
	}

	public goHome(): void {
		this.router.navigateByUrl('');
	}

	public continue(): void {
		if (this.returnUrl.length) {
			this.platformService.warned();
			this.router.navigateByUrl(this.returnUrl);
		} else {
			this.goHome();
		}
	}
}
