<div class="row h-100">
    <div class="col d-flex justify-content-center align-items-center">
        <div class="row not-found-container">
            <div class="col d-flex inner-container text-center">
                <span class="material-icons not-found-icon">help</span>
                <h1>404</h1>
                <h4>Whoops! This page can't be found</h4>
                <button mat-raised-button class="tbp" routerLink="/"><mat-icon>home</mat-icon>&nbsp;&nbsp;Home</button>
            </div>
        </div>
    </div>
</div>
