import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CandidateAuthGuard } from './guard/candidate-auth.guard';
import { ErrorPageComponent } from './site/error/error-page/error-page.component';
import { TroubleshootPageComponent } from './site/error/troubleshoot-page/troubleshoot-page.component';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { PlatformWarningComponent } from './site/platform-warning/platform-warning.component';

const routes: Routes = [
	{ path: '', loadChildren: () => import('./site/home/home.module').then(m => m.HomeModule) },
	{ path: 'login', loadChildren: () => import('./site/login/login.module').then(m => m.LoginModule) },
	{ path: 'tutorial', loadChildren: () => import('./site/tutorial/tutorial.module').then(m => m.TutorialModule) },
	{ path: 'exam', loadChildren: () => import('./site/exam/exam.module').then(m => m.ExamModule), canLoad: [CandidateAuthGuard] },
	{ path: 'preview', loadChildren: () => import('./site/preview/preview.module').then(m => m.PreviewModule) },
	{ path: 'upload', loadChildren: () => import('./site/upload/upload.module').then(m => m.UploadModule) },
	{ path: 'connection', loadChildren: () => import('./site/connection/connection.module').then(m => m.ConnectionModule) },
	{ path: 'error/:errorCode', component: ErrorPageComponent },
	{ path: 'troubleshoot', component: TroubleshootPageComponent },
	{ path: 'platform', component: PlatformWarningComponent },
	{ path: 'mock', loadChildren: () => import('./site/mock/mock.module').then(m => m.MockModule)},
	{ path: '**', component: NotFoundComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
