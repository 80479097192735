import { NgModule, ModuleWithProviders } from '@angular/core';
import { AppDatabase } from './app.database';
import { IDbContext } from './database-context.interface';
import { DATABASE_CONFIG_TOKEN } from './config/database-config-token';

export function DatabaseFactory(config: IDbContext) {
	return new AppDatabase(config);
}

@NgModule()
export class DatabaseModule {
	static forRoot(config: IDbContext): ModuleWithProviders<DatabaseModule> {
		return {
			ngModule: DatabaseModule,
			providers: [
				{ provide: DATABASE_CONFIG_TOKEN, useValue: config },
				{ provide: AppDatabase, useFactory: DatabaseFactory, deps: [DATABASE_CONFIG_TOKEN] },
			]
		};
	}
}
