import { IDbContext } from '../database-context.interface';

export const FSKDatabaseConfig: IDbContext = {
	databaseName: 'FSKDatabase',
	databaseVersion: 0.2,
	schema: {
		keyValues: 'key',
		candidateQuestions: 'candidateQuestionId, orderIndex, sectionId, flagged, responseCount',
		questionAnswers: 'questionAnswerId, candidateQuestionId',
		assets: 'assetId, candidateQuestionId',
		questionSections: 'questionSectionId, orderIndex',
		examLogs: '',
		timedBlocks: 'index'
	}
};
