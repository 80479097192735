import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApplicationError } from '../../../model/error';
import { ApplicationErrorHandler } from '../../../service/error/application-error-handler';

@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
	public error!: ApplicationError;
	public id!: string;
	public technicalDetailsPanelOpen: boolean = false;
	public tooltipText: string = 'Copy me!';

	private defaultError: ApplicationError = {
		technicalName: 'The error code is either invalid or nonexistant, which I guess is the same thing here',
		friendlyName: 'Unknown Error',
		errorCode: 0,
		actionText: "",
		action: () => {},
		triggerActionImmediately: false
	};

	constructor(private applicationErrorHandler: ApplicationErrorHandler,
		private activatedRoute: ActivatedRoute) { }

	public ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params: Params) => {
			this.id = params['id'];
		});

		let errorCode: number = 0;
		try {
			errorCode = Number(this.activatedRoute.snapshot.paramMap.get('errorCode'));
		} catch {
			errorCode = 0;
		}

		this.error = this.applicationErrorHandler.getErrorByErrorIdentifier(errorCode) || this.defaultError;
	}

	public triggerErrorAction(): void {
		if (this.error.action) {
			this.error.action();
		}
	}

	public toggleTechnicalDetailsPane(): void {
		this.technicalDetailsPanelOpen = !this.technicalDetailsPanelOpen;
	}

	public copy(e: Event): void {
		e.stopPropagation();
		e.preventDefault();
		navigator.clipboard.writeText(this.id).then(() => {
			this.setTooltipTextSuccess();
		}).catch(() => {
			this.setTooltipTextFailure();
		});
	}

	public setTooltipTextDefault(): void {
		setTimeout(() => {
			this.tooltipText = 'Copy me!';
		}, 50);
	}

	public setTooltipTextSuccess(): void {
		this.tooltipText = 'Successfully copied!';
	}

	public setTooltipTextFailure(): void {
		this.tooltipText = 'Unable to copy!';
	}
}
