<div class="row h-100">
    <div class="col d-flex justify-content-center align-items-center">
        <div class="row" style="width: 30rem; height: 30rem;">
            <div class="col d-flex inner-container text-center">
                <h3>Your browser is unsupported</h3>
                <h4>If you choose to continue with your exam on this browser, you may encounter issues</h4>
                <button mat-raised-button class="tbp" (click)="goHome()">Back to Home</button>
                <button mat-raised-button class="tbp" (click)="continue()">Continue</button>
            </div>
        </div>
    </div>
</div>